import { Button, clsx, useNotificationToast } from '@sumup/circuit-ui';
import { FC, useContext, useState } from 'react';
import { Document } from '@contentful/rich-text-types';

import style from './style.module.scss';

import {
  ShopContext,
  MAX_QUANTITY,
  SUMUP_ONE_SKU,
} from '~/domains/shop/providers/ShopProvider';
import { findProductInOrder } from '~/domains/shop/providers/ShopProvider/utils';
import { WebsiteProduct } from '~/shared/api-controllers/shared/transformers/products';
import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import { CUSTOM_PRIMARY_COLOR_OPTS } from '~/shared/components/RichText/components/EmbeddedButton/constants';
import SiteContext from '~/shared/providers/SiteContext';

type Props = {
  product: WebsiteProduct;
  label: Document | string;
  soloDesign?: Record<string, string>;
  customPrimaryColor?: string;
  fullWidth?: boolean;
  isAlignedToAllButtons?: boolean;
  variant: 'primary' | 'secondary' | 'tertiary';
};
export const AddToCart: FC<Props> = ({
  product,
  label,
  soloDesign = {},
  customPrimaryColor,
  fullWidth = false,
  isAlignedToAllButtons = false,
  variant,
}) => {
  const { addToCart: addItemToCart, order } = useContext(ShopContext);
  const { cartLabels } = useContext(SiteContext);
  const { setToast } = useNotificationToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!label) {
    return null;
  }

  const sumUpOneInCart = findProductInOrder(order, SUMUP_ONE_SKU);
  const productInCart = findProductInOrder(order, product?.skuCode);
  const productQuantity = productInCart?.quantity;
  const onClick = async () => {
    setIsLoading(true);
    const addedSkus = await addItemToCart(product.skuCode);
    if (addedSkus?.includes(product.skuCode)) {
      setToast({
        variant: 'success',
        headline: cartLabels.itemAdded,
        body: product.productName,
      });
    }
    setIsLoading(false);
  };

  const hasPrimaryStyles =
    variant === 'primary' &&
    customPrimaryColor === CUSTOM_PRIMARY_COLOR_OPTS.PURPLE;
  const hasSecondaryStyles =
    variant !== 'primary' && soloDesign && soloDesign.enableRoundedDesign;

  return (
    <Button
      loadingLabel={cartLabels?.loadingLabel}
      isLoading={isLoading}
      onClick={onClick}
      className={clsx(
        style.button,
        hasPrimaryStyles && style.button__primary,
        hasSecondaryStyles && style.button__secondary,
        fullWidth && style.full__width,
        isAlignedToAllButtons && style.aligned,
      )}
      variant={variant}
      disabled={
        Boolean(productInCart && sumUpOneInCart) ||
        productQuantity >= MAX_QUANTITY
      }
    >
      {typeof label === 'string' ? (
        label
      ) : (
        <RichText
          renderNode={inline.createRenderNode(inline.ONE, undefined, {
            text: {
              as: 'strong',
              variant: 'highlight',
              soloDesign,
            },
          })}
          richText={label}
        />
      )}
    </Button>
  );
};
